import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
} from "@angular/core";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { GlobalConstants } from "../global-variables";
import { ApiService } from "../api.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthenticationService } from "../authentication.service";
import { ToastrService } from "ngx-toastr";
import { Store } from "@ngrx/store";
import * as countryActions from "src/app/store/actions/countries.action";
import { select } from "@ngrx/store";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CampaignBanners } from "../../vistara-benefit/interfaces/campaign-banners";
import { catchError, switchMap, takeUntil } from "rxjs/operators";
import { of, Subject } from "rxjs";
@Component({
  selector: "app-authentication",
  templateUrl: "./authentication.component.html",
  styleUrls: ["./authentication.component.scss"],
  providers: [GlobalConstants],
})
export class AuthenticationComponent implements OnInit {
  @Input() showAuthentication: boolean = false;
  @Output() reloadHeader = new EventEmitter();
  IDLE_TIMEOUT = 900;
  _idleSecondsTimer = null;
  _idleSecondsCounter = 0;
  showLoggedOut: boolean = false;
  stepToShow: string;
  client: any;
  allCountries: any = [];
  selectedCountry: any;
  isCountryDropdownOpen: any;
  userAuthMode: any;
  showStepZero: boolean = false;

  ifExistingUser: boolean = true;
  invalidUser: boolean = false;
  userSearching: boolean = false;
  errorRequestingOtp: boolean = false;
  invalidOtp: boolean = false;
  validOtp: boolean = false;
  invalidCard: boolean = false;
  userId: any;
  urlcode: any;
  uid: any;
  apiclient: any;
  otpInp: any;
  authenticated_user: boolean = false;
  mobileInputBtnActive: any = false;
  emailPattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  createWithUserForm: any = new FormGroup({
    mobile: new FormControl("", Validators.required),
    email: new FormControl("", Validators.email),
  });
  get f() {
    return this.createWithUserForm.controls;
  }
  mobNum: any;
  validateUserOtpForm: any = new FormGroup({
    userId: new FormControl(""),
    otp: new FormControl(""),
  });
  addCardForm: any = new FormGroup({
    binFirst4: new FormControl(""),
    binLast4: new FormControl(""),
  });

  isload1: boolean = false;
  isload2: boolean = false;
  loginProgress: boolean = false;
  timer: any = "initial";
  viewTimer: any = 0;
  timerToShow: any;
  userStatus: any = "login";
  userCards: any = [];
  binError: boolean = false;
  binInvalid: boolean = false;
  cardVerified: boolean = false;
  submittedForm1: boolean;
  errorRequestingOtpMsg: string;
  ref_no: any;
  searchLoader: boolean = false;
  reloaded: boolean = false;
  otpVerifying: boolean = false;
  showNoCards: boolean = false;
  showNoCardsMessage: any = "";
  fetchingCard: boolean = false;
  loginEventValidatingCard: boolean = false;
  cardNum: any;
  processedToken = false;
  featuredCountryCodes = ["IND", "SGP", "ARE", "AUS", "USA", "GBR"];
  popularCountries = [];
  otherCountries = [];
  showMoreCountries = false;
  currentStep = 0;
  showNtbUser = false;
  cancelableAuth = false;
  customer_type: any;
  returnUrl: any;
  public campaignId: string = "";
  private unsubscribe$: Subject<void> = new Subject<void>();

  // bin;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _apiService: ApiService,
    private _auth: AuthenticationService,
    public GlobalConstants: GlobalConstants,
    private toastr: ToastrService,
    public matDialogRef: MatDialogRef<AuthenticationComponent>,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly store: Store
  ) {
    if (
      localStorage.getItem("firstLoad") &&
      !localStorage.getItem("currentUser")
    ) {
      this.removeLocalStorage();
    }
    this.route.queryParams.subscribe((params) => {
      if (params.code) {
        this.urlcode = params.code;
      } else if (params.data) {
        this.urlcode = params.data;
        // this.urlcode = 'cKG3tlZT+OB3R4X60H60obFPbWCWcegdboFgUU1CNo1IgYgDq4vh3mCzBDppW/aDHynMvuIailvvQPExCS3ZGilNQxctAtxCedy2RUH+ThlCY42Jl7lWtAp/FwF9YKtaojshX2lyaQ047vxQsTHwQhZzMlg6SYVNgdajTXqLr89roh5//ChPsJ36v63v6FMuLw9+VG9RCBMizbI4Ta2JRMpeuWH/B6j2YXbDYaUNf6Kw5069IVvfoQzOWgV8QGCeyiiR7V/aDqlnSOhdGN9qD/VBa1LWey62to2aubiaN4sGJ1+lQlJ/EsMHpDlzmLSnpC2/uG9uje0VXAm/VEyqDnQtKR/OmM7ltOioTGQRszuJm30/lNbku4SUhsAeuuhxwqC5UfxOemfo1ZZ4sZ3L/g2frQGCP9dZW3KIVShW5eYJi/bek6tjxCaJa9snMF1dn9xmwReUVJFhPFi81bjdyY78vkais5lHVjomKm87+V+ulucjY0nxLSIWbVoZ9h6C1bZiJc+TFL5Ot8fMm5iw2S+nGKjoNfnlUH9zkAEhytfWvhH1vmktM3QcnGhQV6xBZvAjHv6unr57ng/Ct/nqHt3S82bWi8wRhUldS9JdNH4WcK1iO0d7Y2WzTYKuuxTuOaJ70bJJaXoir9Q9sV/YItRH6BUHEKucawtfrPqhks1r1iwL/hbFFl83Besb2qme9V1QbHqWQlLBetxDDBgP3DRSUGAJyRXJRkG5koMuuK0AK20bXa89X20JytSdDT4mDuqyRcNZkp9SoEqbhErx3GR0sWaZ1vjB2nXeqOSTzpK27OmZAha8Nn8mYiRriZlJsxtDhDVhMzXCxtDvv4RMocmJihMuHmgf0DmozvNSj/I9I59pyB7RxY2V8szGF5yWlP9LTctPAQAfzqGs4uBHN/JVT03PsTRJp1VmA4UWrB5wnrbahYpcpomNMjS/blegT+sfjZURye/LRGwjJcZxe6cG8A/q95LQ+Y4I5o4jA04Es2RppzEf/5tXmR80GFWWCZp9kbmX+aY4WVmzPNduzo6Im0YweCvGb1kPLUu+UJyywjtNVGkUvzRM4Qj4dPlgpMyjCRFwyO4Gn/F8i8lud6W1X8at5PfcMPP3/tCwpGoT9yjId3pTYB9snjsICwgb';
        // router.navigate([], { queryParams: { code: null }, queryParamsHandling: 'merge' })
        let qParams: Params = {};
        this.setQueryParams(qParams);
      } else if (params.tk) {
        this.removeLocalStorage();
        if (atob(params.tk) != "no-token") {
          this.urlcode = atob(params.tk);
          this.processedToken = true;
        }
        if (params.ru) {
          let returnUrl = JSON.stringify(atob(params.ru).trim());
          localStorage.setItem("returnUrl", returnUrl);
        }
        if (params.cf) {
          let channel_ref = params.cf.toLowerCase();
          localStorage.setItem("channel_ref", channel_ref);
          if (channel_ref == "mb") {
            localStorage.setItem("appUser", "true");
          }
        }
        if (params.ct) {
          let customer_type = atob(params.ct).toLowerCase();
          localStorage.setItem("customer_type", customer_type.toLowerCase());
          let qParams: Params = {};
          this.setQueryParams(qParams);
          if (customer_type && customer_type != "etb") {
            this.reloadInit();
            this.loginProgress = false;
            localStorage.setItem("urlcode", "no-token");
            return false;
          }
        }
      } else if (params.tok) {
        this.urlcode = atob(params.tok);
        this.processUserWithToken(this.urlcode);
        this.loginProgress = true;
        console.log(params.tok);
      } else if (params.token) {
        this.removeLocalStorage();
      }
    });
    if (this.urlcode && this.GlobalConstants.client != "kotak-mahindra-bank") {
      this.loginProgress = true;
      if (!this.processedToken) {
        this.processUrlToken();
      } else {
        this.processEncodedUrlToken();
      }
    } else if (localStorage.getItem("currentUser")) {
      let current_user = JSON.parse(localStorage.getItem("currentUser"));
      this.authenticated_user = true;
    } else {
      this.authenticated_user = false;
      if (
        this.GlobalConstants.data.personalized_config.force_auth ||
        this.showAuthentication
      ) {
        this.restartLogin();
      }
    }

    this._auth.errorEvent.pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      if (res) {
        let customer_type = localStorage.getItem("customer_type");
        if (customer_type) {
          if (
            customer_type.toLowerCase() === "ntb" ||
            customer_type.toLowerCase() === "noetb" ||
            customer_type.toLowerCase() === "ntb-logout"
          ) {
            this.customer_type = customer_type.toLowerCase();
            this.returnUrl = localStorage.getItem("returnUrl")
              ? JSON.parse(localStorage.getItem("returnUrl"))
              : null;
            this.showNtbUser = true;
          }
        } else if (localStorage.getItem("returnUrl")) {
          const returnUrl = JSON.parse(localStorage.getItem("returnUrl"));
          // localStorage.clear();
          // location.replace(returnUrl);
        } else if (localStorage.getItem("diningSearchParams")) {
          const diningSearchParams = localStorage.getItem("diningSearchParams");
          localStorage.clear();
          sessionStorage.clear();
          localStorage.setItem("diningSearchParams", diningSearchParams);
          this.restartLogin(true);
          // location.replace(returnUrl);
        } else {
          this.restartLogin();
        }
      }
    });
    this._auth.reloadInit.pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      if (res) {
        this.reloadInit();
      }
    });
  }
  setQueryParams(qParams) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: qParams,
      queryParamsHandling: "",
    });
  }
  removeLocalStorage() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("card_verified");
    localStorage.removeItem("returnUrl");
    localStorage.removeItem("customer_type");
    localStorage.removeItem("channel_ref");
    localStorage.removeItem("userCards");
    localStorage.removeItem("cardSelected");
    localStorage.removeItem("userCard");
  }
  processEncodedUrlToken() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("card_verified");

    this.loginProgress = false;
  }

  triggerAuthentication() {
       if (localStorage.getItem("returnUrl")) {
          this.returnUrl = JSON.parse(localStorage.getItem("returnUrl"));
        } else if (localStorage.getItem("diningSearchParams")) {
          const diningSearchParams = localStorage.getItem("diningSearchParams");
          localStorage.clear();
          sessionStorage.clear();
          localStorage.setItem("diningSearchParams", diningSearchParams);
          this.restartLogin(true);
        } else {
          this.restartLogin();
        }
  }

  processUrlToken() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("card_verified");
    this._apiService
      .createTokenUser(this.urlcode, this.uid || null, this.apiclient || null)
      .subscribe(
        (res: any) => {
          if (res.token) {
            var user = {
              token: res.token,
            };

            if (user && user.token) {
              localStorage.setItem("currentUser", JSON.stringify(user));
              localStorage.setItem("card_verified", "true");
              localStorage.setItem("urlcode", this.urlcode);
              this.fetchingCard = true;
              this._apiService.getUserDetails().subscribe((resp: any) => {
                this.fetchingCard = true;
                this._apiService.getCards().subscribe((response) => {
                  this.fetchingCard = false;
                  if (response && response.data && response.data.length > 0) {
                    let card = response.data.find(
                      (card) => card.id == res.card_id
                    );
                    var userCards = [];
                    if (this.GlobalConstants.client === "idfc-bank-gi") {
                      userCards = response.data.filter((card) => {
                        return (
                          card.attributes.card_type.toLowerCase() === "debit"
                        );
                      });
                    } else {
                      userCards = response.data;
                    }
                    localStorage.setItem("userCard", JSON.stringify(card));
                    localStorage.setItem(
                      "userCards",
                      JSON.stringify(userCards)
                    );
                    this.authReload();
                    // this.router.navigate([], { queryParams: { code: null }, queryParamsHandling: 'merge' })
                    // window.location.href = "/";
                    setTimeout(() => {
                      this.loginProgress = false;
                    }, 1000);
                  } else {
                    window.location.href = "/";
                  }
                });
              });
            }
            this.processUserWithToken(res.token);
          } else if (res.req_data && res.req_data.customer_type) {
            localStorage.setItem("customer_type", res.req_data.customer_type);
          }
        },
        (err) => {
        }
      );
  }
  processUserWithToken(token) {
    var user = {
      token: token,
    };

    if (user && user.token) {
      localStorage.setItem("currentUser", JSON.stringify(user));
      localStorage.setItem("card_verified", "true");
      localStorage.setItem("urlcode", this.urlcode);
      this.fetchingCard = true;
      this._apiService.getUserDetails().subscribe((res: any) => {
        this.fetchingCard = true;
        this._apiService.getCards().subscribe((response) => {
          this.fetchingCard = false;
          if (response && response.data && response.data.length > 0) {
            if (this.GlobalConstants.client === "idfc-bank-gi") {
              this.userCards = response.data.filter((card) => {
                return card.attributes.card_type.toLowerCase() === "debit";
              });
            } else {
              this.userCards = response.data;
            }
            let card;
            if (this.userCards.length > 0) {
              card = this.userCards[0];
            }
            localStorage.setItem("userCard", JSON.stringify(card));
            localStorage.setItem("userCards", JSON.stringify(this.userCards));
            this.authReload();
            // this.router.navigate([], { queryParams: { code: null }, queryParamsHandling: 'merge' })
            // window.location.href = "/";
            setTimeout(() => {
              this.loginProgress = false;
            }, 1000);
          } else {
            window.location.href = "/";
          }
        });
      });
    }
  }
  ngOnInit() {
    this.currentStep = 0;
    this.stepToShow = this.GlobalConstants.data.login_steps[0];

    let card_verified = localStorage.getItem("card_verified");
    if (!card_verified || card_verified == "false") {
      localStorage.removeItem("currentUser");
      this.cardVerified = false;
      this.triggerAuthentication();
    } else {
      this.cardVerified = true;
    }

    // if (sessionStorage.getItem('client')) {
    //   this.client = JSON.parse(sessionStorage.getItem('client'));
    // } else {
    //   this.getClient();
    // }

    if (localStorage.getItem("currentUser")) {
      let current_user = JSON.parse(localStorage.getItem("currentUser"));
      if (this.ifExistingUser) {
        this.gtmLogin(current_user.id, "Existing user");
      }
    }
  }

  gtmLogin(userid, usertype) {
    if (this.GlobalConstants.data.personalized_config.datalayer) {
      window.dataLayer.push({
        event: "Login",
        UserID: userid,
        usertype: usertype,
      });
    }
  }

  doLater() {
    window.dataLayer.push({
      event: "offers_Do-Later",
      EventCategory: "Offers Login",
      EventAction: "I'll do this Later",
      EventLabel: "",
      url: "/vpv/offers/login/do-this-later",
    });
  }

  gtmForm() {
    window.dataLayer.push({
      event: "offers_Login_mobile-number",
      EventCategory: "Offers Login",
      EventAction: "Mobile Number",
      EventLabel: "Continue",
      url: "/vpv/offers/login/mobile-number",
    });
  }

  gtmMobileMismatch() {
    window.dataLayer.push({
      event: "offers_Login_mobile-number-error",
      EventCategory: "Offers Login",
      EventAction: "Mobile Number",
      EventLabel: "Error",
      url: "/vpv/offers/login/mobile-number",
    });
  }

  gtmOtpSuccess() {
    window.dataLayer.push({
      event: "offers_Login_mobile-num_OTP-success",
      EventCategory: "Offers Login",
      EventAction: "Mobile OTP",
      EventLabel: "Success",
      url: "/vpv/offers/login/mobile-number/OTPsuccess",
    });
  }

  gtmOtpError() {
    window.dataLayer.push({
      event: "offers_Login_mobile-num_OTP-error",
      EventCategory: "Offers Login",
      EventAction: "Mobile OTP",
      EventLabel: "Error",
      url: "/vpv/offers/login/mobile-number/OTPerror",
    });
  }

  gtmRandomClose() {
    window.dataLayer.push({
      event: "offers_Login_dropping",
      EventCategory: "Offers Login",
      EventAction: "Cancelled Login",
      EventLabel: "Close Button",
      url: "/vpv/offers/login/dropping",
    });
  }

  reloadInit() {
    this.loginProgress = false;
    this.ngOnInit();
    this.reloadHeader.emit();
  }

  removeLetter(str) {
    this.createWithUserForm.controls["mobile"].setValue(str.replace(/\D/g, ""));
  }
  getClient() {
    this._apiService.getClient(true).subscribe(
      (res: Array<any>) => {
        this.client = res;
        this.userAuthMode = this.client.user_auth_mode;
        sessionStorage.setItem("client", JSON.stringify(this.client));
      },
      (err) => {}
    );
  }

  userSearch() {
    this.isload1 = false;
    this.errorRequestingOtpMsg = "";
    this.userId = "";
    this.ifExistingUser = true;
    if (
      this.createWithUserForm.value.mobile.length >
        this.selectedCountry.min_mobile_digit - 1 &&
      this.createWithUserForm.value.mobile.length <
        this.selectedCountry.max_mobile_digit + 1
    ) {
      this.mobileInputBtnActive = true;
      if (this.GlobalConstants.data.personalized_config.allow_user_create) {
        this.searchLoader = true;
        this.userSearching = true;
        this._auth
          .validateExistingUser(
            this.createWithUserForm.value.mobile,
            this.cardNum
          )
          .subscribe((response) => {
            this.userSearching = false;
            this.searchLoader = false;
            if (
              response &&
              response.mobile == this.createWithUserForm.value.mobile
            ) {
              this.ifExistingUser = true;
              this.userId = response.id;

              if (this.GlobalConstants.data.login_theme == 2) {
                this.cardVerified = true;
                localStorage.setItem("card_verified", "true");
              }
            } else {
              if (this.GlobalConstants.data.personalized_config.external_otp) {
                this.ifExistingUser = true;
                this.userId = response.id;
              } else {
                this.ifExistingUser = false;
                this.userId = "";
                this.createWithUserForm.controls["email"].setValidators([
                  Validators.pattern(this.emailPattern),
                ]);
                this.createWithUserForm.controls[
                  "email"
                ].updateValueAndValidity();
              }
            }
          });
      }
    } else {
      this.mobileInputBtnActive = false;
    }
  }

  closeAuthentication() {
    const diningSearchParams = localStorage.getItem("diningSearchParams");
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("diningSearchParams", diningSearchParams);
    this.matDialogRef.close();
  }

  showMergedOtp: boolean = false;
  authenticateUser(event: any, goTonextStep = true) {
    this.isload1 = true;
    this.errorRequestingOtpMsg = "";
    this.submittedForm1 = true;
    // event.preventDefault();
    if (this.userSearching) {
      this.isload1 = false;
      return false;
    }
    this.gtmForm();
    if (this.createWithUserForm.valid) {
      if (this.GlobalConstants.data.personalized_config.external_otp) {
        this.requestExternalOtp(goTonextStep);
      } else {
        if (
          this.ifExistingUser &&
          this.userId != "" &&
          this.userId != undefined
        ) {
          this.requestPoshvineOtp(goTonextStep);
        } else if (
          !this.GlobalConstants.data.personalized_config.allow_user_create &&
          this.createWithUserForm.value.mobile.length >
            this.selectedCountry.min_mobile_digit - 1 &&
          this.createWithUserForm.value.mobile.length <
            this.selectedCountry.max_mobile_digit + 1
        ) {
          this.searchLoader = true;
          this.userSearching = true;
          this._auth
            .validateExistingUser(
              this.createWithUserForm.value.mobile,
              this.cardNum
            )
            .subscribe((response) => {
              this.userSearching = false;
              this.searchLoader = false;
              if (
                response &&
                response.mobile == this.createWithUserForm.value.mobile
              ) {
                this.ifExistingUser = true;
                this.userId = response.id;
                if (
                  this.GlobalConstants.data.personalized_config.external_otp
                ) {
                  this.requestExternalOtp(goTonextStep);
                } else {
                  this.requestPoshvineOtp(goTonextStep);
                }
              } else {
                this.mobileInputBtnActive = false;
                this.isload1 = false;
                this.ifExistingUser = false;
                this.userSearching = false;
                this.searchLoader = false;
              }
            });
        } else if (
          this.GlobalConstants.data.personalized_config.allow_user_create
        ) {
          this.createUser();
        } else {
          return false;
        }
      }
    }
  }

  requestPoshvineOtp(goTonextStep = true) {
    this._auth
      .requestUserOtp(this.createWithUserForm.value.mobile, this.userId)
      .subscribe(
        (response) => {
          if (response) {
            this.submittedForm1 = false;
            this.countDown(180, () => {
              this.timer = "complete";
            });
            setTimeout(() => {
              this.showStepZero = false;
              if (goTonextStep) {
                this.currentStep = this.currentStep + 1;
                this.stepToShow =
                  this.GlobalConstants.data.login_steps[this.currentStep];
              }
              this.isload1 = false;
            }, 1000);
            setTimeout(() => {
              //<<<---    using ()=> syntax
              document.getElementById("otp-field").focus();
            }, 1800);
          } else {
            this.errorRequestingOtpMsg =
              "OTP Request limit exceeded. Please try after 30 mins.";
            this.errorRequestingOtp = true;
            this.submittedForm1 = false;
            this.isload1 = false;
          }
        },
        (error) => {
          this.errorRequestingOtpMsg =
            "OTP Request limit exceeded. Please try after 30 mins.";
          this.errorRequestingOtp = true;
          this.submittedForm1 = false;
        }
      );
  }
  requestExternalOtp(goTonextStep = true) {
    this._auth
      .externalRequestUserOtp(this.createWithUserForm.value.mobile)
      .subscribe(
        (response) => {
          if (response) {
            this.ref_no = response.ref_no;
            this.submittedForm1 = false;
            this.countDown(180, () => {
              this.timer = "complete";
            });
            setTimeout(() => {
              //<<<---    using ()=> syntax
              this.showStepZero = false;
              this.isload1 = false;
              if (goTonextStep) {
                this.currentStep = this.currentStep + 1;
                this.stepToShow =
                  this.GlobalConstants.data.login_steps[this.currentStep];
              }
            }, 1000);
            setTimeout(() => {
              //<<<---    using ()=> syntax
              document.getElementById("otp-field").focus();
            }, 1800);
          } else {
            this.errorRequestingOtpMsg =
              "Could not generate OTP, Please try again";
            this.errorRequestingOtp = true;
            this.submittedForm1 = false;
          }
          this.isload1 = false;
        },
        (error) => {
          this.errorRequestingOtpMsg =
            "Dear Customer, please check if your mobile number is registered with us";
          this.errorRequestingOtp = true;
          this.submittedForm1 = false;
          this.isload1 = false;
        }
      );
  }
  createUser() {
    this.gtmMobileMismatch();
    this._auth
      .createUser(
        this.createWithUserForm.value.mobile,
        this.createWithUserForm.value.email,
        this.selectedCountry.id,
        this.selectedCountry.calling_codes[0]
      )
      .subscribe(
        (response) => {
          if (response && response.id) {
            this.showStepZero = false;
            this.currentStep = this.currentStep + 1;
            this.stepToShow =
              this.GlobalConstants.data.login_steps[this.currentStep];
            this.submittedForm1 = false;
            this.countDown(180, () => {
              this.timer = "complete";
            });

            setTimeout(() => {
              //<<<---    using ()=> syntax
              document.getElementById("otp-field").focus();
            }, 1000);
            this.userId = response.id;
            this.userStatus = "activation";
          } else {
            this.invalidUser = true;
            this.submittedForm1 = false;
          }
        },
        (error) => {
          this.isload1 = false;
          if (error.error) {
            this.errorRequestingOtpMsg = error.error.errors.join(", ");
          } else {
            this.errorRequestingOtpMsg = "Please check your eligibility.";
          }

          this.errorRequestingOtp = true;
          this.submittedForm1 = false;
        }
      );
  }
  validateUserOtp() {
    if (this.otpInp && this.otpInp.length == 6) {
      this.otpVerifying = true;
      if (this.GlobalConstants.data.personalized_config.external_otp) {
        this._auth
          .externalValidateUserOtp(this.otpInp, this.ref_no, this.mobNum)
          .subscribe(
            (response) => {
              this.otpVerifying = false;
              if (response && response.token) {
                this.gtmOtpSuccess();
                this.showAuthentication = false;
                this.getUserCard();
                localStorage.setItem("card_verified", "true");
              } else {
                this.gtmOtpError();
                this.errorRequestingOtpMsg = "Entered Otp is invalid";
                this.errorRequestingOtp = true;
                this.submittedForm1 = false;
                this.isload2 = false;
              }
            },
            (error) => {
              this.otpVerifying = false;
              this.errorRequestingOtpMsg =
                "Dear Customer, please check if your mobile number is registered with us";
              this.errorRequestingOtp = true;
              this.submittedForm1 = false;
              this.isload2 = false;
              this.gtmOtpError();
            }
          );
      } else {
        this._auth
          .validateUserOtp(
            this.userId,
            this.otpInp,
            this.userStatus,
            this.mobNum
          )
          .subscribe(
            (response: any) => {
              this.otpVerifying = false;
              if (response && response.token) {
                this.gtmOtpSuccess();
                this.stepToShow = null;
                this.validOtp = true;
                if (this.GlobalConstants.data.loginAt == "otp") {
                  if(this.GlobalConstants.client == 'idfc-bank-gi')
                    this.addCard(true, true);
                  else {
                    let current_user = JSON.parse(localStorage.getItem("currentUser"));
                    current_user.token = response.token;
                    this.checkIfBinValid(this.cardNum, this.GlobalConstants.data.card_num_type)
                  }
                  // this.getUserCard();
                } else if (
                  this.GlobalConstants.data.personalized_config.has_card == true
                ) {
                  this.loginEventValidatingCard = true;
                  this.currentStep = this.currentStep + 1;
                  this.stepToShow =
                    this.GlobalConstants.data.login_steps[this.currentStep];
                  if(this.stepToShow === "card") {
                    setTimeout(() => {
                      document.getElementById("first_four_card_digits").focus();
                    }, 1000);
                  }
                  // this.checkIfBinValid();
                } else {
                  this.stepToShow = null;
                  this.showAuthentication = false;
                  this.reloadInit();
                }
              } else {
                this.gtmOtpError();
                this.invalidOtp = true;
                this.validOtp = false;
              }
            },
            (error) => {
              this.otpVerifying = false;
              this.errorRequestingOtpMsg = "Please check the entered OTP";
              this.errorRequestingOtp = true;
              this.gtmOtpError();
              if (
                this.GlobalConstants.data.personalized_config.autocard_check
              ) {
                this.stepToShow = null;
                this.showNoCards = true;
                this.showNoCardsMessage =
                this.GlobalConstants.data.content.autocard_check_card_error;
              }
            }
          );
      }
    }
  }

  getUserCard() {
    this.fetchingCard = true;
    this._apiService.getCards().subscribe((response) => {
      this.fetchingCard = false;
      if (
        response &&
        response.data &&
        (response.data.length > 0 || response.data.attributes)
      ) {
        let thebin = localStorage.getItem("thebin");
        var currentCard;
        if (response.data.attributes) {
          currentCard[0] = response.data;
        } else {
          currentCard = response.data.filter((x) => {
            return x.attributes.bin == thebin;
          });
        }
        var card;
        if (currentCard.length == 1) {
          card = JSON.stringify(currentCard[0]);
          localStorage.setItem("userCard", card);
          let userCards;
          if (this.GlobalConstants.client === "idfc-bank-gi") {
            userCards = response.data.filter((card) => {
              return card.attributes.card_type.toLowerCase() === "debit";
            });
          } else {
            userCards = response.data;
          }
          localStorage.setItem("userCards", JSON.stringify(userCards));
          if (this.GlobalConstants.data.loginAt == "otp") {
            this.checkIfBinValid(
              currentCard[0].attributes.bin,
              currentCard[0].attributes.bin_type
            );
          }
        } else {
          this.addCard(true, true);
        }
        this.stepToShow = null;
        if (!this.GlobalConstants.data.personalized_config.autocard_check) {
          this.stepToShow = "card";
        }
        if (this.GlobalConstants.data.personalized_config.skip_card_if_exist) {
          this.cardVerified = true;
          localStorage.setItem("card_verified", "true");
        }
        if (
          this.userAuthMode == "preset_bin" ||
          this.GlobalConstants.data.loginAt == "card" ||
          this.GlobalConstants.data.loginAt == "otp"
        ) {
          if (this.cardVerified == true) {
            this.stepToShow = null;
            this.showAuthentication = false;
            this.reloadInit();
          } else {
            this.stepToShow = "card";
            this.cardVerified = false;
            localStorage.setItem("card_verified", "false");
          }
        } else {
          if (this.GlobalConstants.data.loginAt == "otp") {
            // localStorage.removeItem('userCard');
            // localStorage.setItem('card_verified', "true");
            this.showAuthentication = false;
            // this.reloadInit();
          }
        }
      } else {
        if (
          this.userAuthMode == "preset_bin" ||
          this.GlobalConstants.data.loginAt == "card"
        ) {
          localStorage.setItem("card_verified", "false");
        }
        this.stepToShow = null;
        if (this.GlobalConstants.data.personalized_config.autocard_check) {
          this.showNoCards = true;
          this.showNoCardsMessage =
          this.GlobalConstants.data.content.autocard_check_card_error;
          localStorage.removeItem("currentUser");
        } else {
          this.stepToShow = "card";
        }
        if (this.GlobalConstants.data.loginAt == "otp") {
        } else {
        }
        if (this.GlobalConstants.data.loginAt == "external") {
          location.reload();
        }
      }
    });
  }

  addCard(reload = false, binCheck = false) {
    let thebin = localStorage.getItem("thebin");
    this._apiService.addCard(thebin, "first6").subscribe((response) => {
      if (response && response.data && response.data.attributes) {
        let card = JSON.stringify(response.data);
        localStorage.setItem("userCard", card);
        localStorage.setItem("card_verified", "true");
        this.stepToShow = null;
        if (binCheck) {
          this.checkIfBinValid(thebin, "first6");
        } else if (reload) {
          this.reloadInit();
        }
      }
    });
  }
  checkIfBinValid(bin, bin_type, card_id?) {
    if (card_id) {
      this._apiService.switchCard(card_id).subscribe((res) => {
        if (
          res &&
          res.data &&
          res.data.attributes.bin == bin &&
          (res.data.attributes.status == "bin_verified"
          || res.data.attributes.status == "3ds_verified")
        ) {
          let current_user = JSON.parse(localStorage.getItem("currentUser"));
          current_user.token = res.data.attributes.session_token;
          if (current_user.skipExceptCard) {
            current_user.skipExceptCard = false;
          }
          localStorage.setItem("currentUser", JSON.stringify(current_user));
          localStorage.setItem("card_verified", "true");
          this._auth.isCardVerified.emit(true);
          localStorage.setItem("userCard", JSON.stringify(res.data));
          this.cardVerified = true;

          this.binError = false;
          this.binInvalid = false;
          this.loginProgress = false;
          this.addLocation(true);
        } else {
          localStorage.setItem("card_verified", "false");
        }
      });
    } else {
      this._apiService.validateCard(bin, bin_type).subscribe((res) => {
        this.isload2 = false;
        if (
          res &&
          res.data &&
          res.data.attributes.bin == bin &&
          (res.data.attributes.status == "bin_verified"
          || res.data.attributes.status == "3ds_verified")
        ) {
          let current_user = JSON.parse(localStorage.getItem("currentUser"));
          current_user.token = res.data.attributes.session_token;
          localStorage.setItem("currentUser", JSON.stringify(current_user));
          this.cardVerified = true;
          localStorage.setItem("card_verified", "true");
          this._auth.isCardVerified.emit(true)
          let card = JSON.stringify({ card_id: res.data.attributes.id });
          localStorage.setItem("userCard", JSON.stringify(res.data));
          this.binError = false;
          this.binInvalid = false;
          this.loginProgress = false;
          this.addLocation(true);
        } else {
          this.loginProgress = false;
          this.binError = true;
          if (this.userAuthMode == "preset_bin") {
            localStorage.setItem("card_verified", "false");
          }
        }
      });
    }
  }

  authReload() {
    const cardName = JSON.parse(localStorage.getItem("userCard"))?.attributes?.card_detail.name;
    const cardByPathName = this.GlobalConstants.mapCardNameToPath?.[cardName];
    if (cardByPathName && this.campaignId === "818e37d3-179a-4f2e-b3e7-0d9756f6b79d") {
this.router.navigate([cardByPathName({campaignId: this.campaignId})]);
    } else if (
      this.GlobalConstants.data.personalized_config.force_auth ||
      this.urlcode
    ) {
      window.location.href = "/";
    } else if (this.GlobalConstants.client == "idfc-bank") {
      location.reload();
    } else {
      this.reloadInit();
    }
  }
  addCardFormSubmit() {
    if (this.binError) {
      this._auth.logout();
      window.location.href = "/";
    }
    this.isload2 = true;
    let bin;
    if (this.GlobalConstants.data.card_num_type == "first4_last4") {
      bin =
        this.addCardForm.value.binFirst4.toString() +
        "_" +
        this.addCardForm.value.binLast4.toString();
    } else if (this.GlobalConstants.data.card_num_type == "first6") {
      bin =
        this.addCardForm.value.binFirst4.toString() +
        this.addCardForm.value.binLast4.toString();
    } else if (this.GlobalConstants.data.card_num_type == "first8") {
      bin =
        this.addCardForm.value.binFirst4.toString() +
        this.addCardForm.value.binLast4.toString();
    } else if (this.GlobalConstants.data.card_num_type == "last4") {
      bin = this.addCardForm.value.binLast4.toString();
    }
    // let bin = 123456;
    this.cardNum = bin;
    if (
      this.GlobalConstants.client == "idfc-bank-gi"
    ) {
      localStorage.setItem("thebin", bin);
      this.cardValidation(bin);
    } else {
      // this.checkIfBinValid(bin, this.GlobalConstants.data.card_num_type);
      this.currentStep = this.currentStep + 1;
      this.stepToShow = this.GlobalConstants.data.login_steps[this.currentStep];
    }
  }

  getAllCountries() {
    // this._apiService.getAllCountries().subscribe(
    //   (res: any) => {
    this.store
      .pipe(select((state: any) => state.countries))
      .subscribe((countries) => {
        if (countries && countries.countries) {
          this.allCountries = countries.countries.data;
          this.popularCountries = [];
          this.otherCountries = [];
          // this.allCountries = res.data;
          if (this.allCountries && this.allCountries.length > 0) {
            this.allCountries.forEach((obj) => {
              obj.attributes.min_mobile_digit = obj.attributes?.mobile_allowed_lengths?.[0];
              obj.attributes.max_mobile_digit = obj.attributes?.mobile_allowed_lengths?.[1];
              if (this.featuredCountryCodes.includes(obj.attributes.iso_code)) {
                this.popularCountries.push(obj);
              } else {
                this.otherCountries.push(obj);
              }
            });
            var country = this.allCountries.filter((x) => {
              return x.attributes.name == "India";
            });

            if (country.length > 0) {
              this.selectedCountry = country[0].attributes;
              localStorage.setItem(
                "locationData",
                JSON.stringify(this.selectedCountry)
              );
            } else {
              this.selectedCountry = null;
            }

            // sort popular countries array w.r.t featured array
            this.popularCountries.sort((a, b) => {
              return (
                this.featuredCountryCodes.indexOf(a.attributes.iso_code) -
                this.featuredCountryCodes.indexOf(b.attributes.iso_code)
              );
            });

            // alphabetically sort other countries
            this.otherCountries.sort(function (a, b) {
              var textA = a.attributes.name.toUpperCase();
              var textB = b.attributes.name.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
          } else {
            this.selectedCountry = null;
          }

          //   }
          // )
        } else {
          this.store.dispatch(countryActions.getCountries({}));
        }
      });
  }

  setCountry(country) {
    this.isCountryDropdownOpen = false;
    this.selectedCountry = country.attributes;
    localStorage.setItem("locationData", JSON.stringify(this.selectedCountry));
  }
  onKeyph(e) {
    this.binError = false;
    this.binInvalid = false;
    e.target.value = e.target.value.replace(/[^\d]/g, "").trim();
  }
  keytab(event, el) {
    this.binError = false;
    this.binInvalid = false;
    this.isload2 = false;
    if (event.currentTarget.value.length == 4) {
      // document.getElementById('last_four_card_digits').focus();
      el.focus();
    }
  }
  btnActive: boolean = false;

  resetLoginAgain() {
    this.binError = false;
    this.submittedForm1 = false;
    this.fetchingCard = false;
    this.searchLoader = false;
    this.isload1 = false;
    this.isload2 = false;
    if (this.GlobalConstants.client === "idfc-bank-gi") {
      this.stepToShow =
        this.GlobalConstants.client === "idfc-bank-gi"
          ? this.GlobalConstants.data.login_steps[1]
          : this.GlobalConstants.data.login_steps[0];
      this.currentStep = 1;
    } else {
      this.stepToShow = this.GlobalConstants.data.login_steps[0];
      this.currentStep = 0;
    }
    this.mobNum = undefined;
    this.mobileInputBtnActive = false;
    this.errorRequestingOtp = false;
    this.errorRequestingOtpMsg = "";

    if (this.createWithUserForm.controls) {
      if (this.createWithUserForm.controls["mobile"]) {
        this.createWithUserForm.controls["mobile"].setValue("");
      }
      if (this.createWithUserForm.controls["otp"]) {
        this.createWithUserForm.controls["otp"].setValue("");
      }
    }
    this.otpInp = "";
    this.userSearching = false;
    this.validOtp = false;
    clearInterval(this.int), function () {};
  }
  cardValidation(bin) {
    this._apiService.cardValidation(bin).subscribe((res) => {
      this.isload2 = false;
      if (res && res.success) {
        this.cardVerified = true;
        localStorage.setItem("card_verified", "true");
        this.currentStep = this.currentStep + 1;
        if (this.GlobalConstants.data.login_steps[this.currentStep]) {
          this.stepToShow =
            this.GlobalConstants.data.login_steps[this.currentStep];
        } else {
          this.stepToShow = null;
        }
        this.binError = false;
        this.binInvalid = false;
      } else {
        this.binError = true;
        if (this.userAuthMode == "preset_bin") {
          localStorage.setItem("card_verified", "false");
        }
      }
    });
  }
  checkbtn() {
    if (this.GlobalConstants.data.card_num_type == "first6") {
      if (
        this.addCardForm.value.binFirst4.length +
          this.addCardForm.value.binLast4.length ==
        6
      ) {
        this.btnActive = true;
        if (this.GlobalConstants.data.personalized_config.single_step_login) {
          this.addCardFormSubmit();
        }
      } else {
        this.btnActive = false;
      }
    } else if (this.GlobalConstants.data.card_num_type == "last4") {
      if (this.addCardForm.value.binLast4.length == 4) {
        this.btnActive = true;
      }
    } else {
      if (
        this.addCardForm.value.binFirst4.length +
          this.addCardForm.value.binLast4.length ==
        8
      ) {
        // var bin = this.addCardForm.value.binFirst4.toString() + "_" + this.addCardForm.value.binLast4.toString();
        // this.checkIfBinValid(bin, 'first4_last4');

        this.btnActive = true;
      } else {
        this.btnActive = false;
      }
    }
  }

  backkeytab(event, firstFocusable) {
    this.binError = false;
    this.binInvalid = false;
    this.isload2 = false;

    if (event.currentTarget.value.length == 0 && event.key == "Backspace") {
      let elem = document.getElementById("first_four_card_digits");
      // elem.value = elem.value.slice(0, 3);
      elem.focus();
    }
  }

  int: any;
  countDown(i, callback) {
    callback = callback || function () {};
    this.int = setInterval(() => {
      this.timer = "started";
      this.viewTimer = i;
      const minutes: number = Math.floor(i / 60);
      this.timerToShow =
        minutes + ":" + (i - minutes * 60).toString().padStart(2, "0");

      i-- || (clearInterval(this.int), callback());
    }, 1000);
  }

  resetCountDown(i, callback) {
    callback = callback || function () {};
    var int = setInterval(() => {
      this.timer = "started";
      this.viewTimer = i;
      const minutes: number = Math.floor(i / 60);
      this.timerToShow =
        minutes + ":" + (i - minutes * 60).toString().padStart(2, "0");

      i-- || (clearInterval(int), callback());
    }, 1000);
  }

  restartLogin(skipLogout = false) {
    if (!skipLogout) {
      this._auth.logout();
    }

    if (!this.allCountries || this.allCountries.length == 0) {
      this.getAllCountries();
    }
    this.currentStep = 0;
    this.stepToShow = this.GlobalConstants.data.login_steps[0];

    this.userSearching = false;
    this.validOtp = false;
    this.createWithUserForm.patchValue({ mobile: "" });
    this.createWithUserForm.patchValue({ email: "" });
    this.otpInp = "";
    this.addCardForm.patchValue({ binFirst4: "" });
    this.addCardForm.patchValue({ binLast4: "" });
  }

  clearOtp() {
    document.getElementById("otp-field").focus();
    this.otpInp = "";
  }

  goToLogin() {
    location.reload();
  }

  addLocation(shouldReload) {
    let userLocation = JSON.parse(localStorage.getItem("userLocation"));

    this._apiService.getCampaignBanners()
    .pipe(
      catchError((error) => {
        console.error('Error fetching campaign banners:', error);
        return of(null);
      }),
      switchMap((res: CampaignBanners) => {
        if (res?.data?.length) {
          this.campaignId = res.data?.[0]?.attributes?.campaign_id;
          if (userLocation?.country?.id) {
            let params = {
              country_id: userLocation.country.id,
            };
            if (userLocation.state && userLocation.state.id) {
              params["city_id"] = userLocation.state.id;
            }
            return this._apiService.addLocation(params)
              .pipe(
                catchError((error) => {
                  console.error('Error adding location:', error);
                  return of(null);
                })
              );
          } else {
            return of(null);
          }
        } else {
          return of(null);
        }
      }),
      takeUntil(this.unsubscribe$)
    )
    .subscribe(() => {
      if (shouldReload) {
        this.authReload();
      }

      if (this.data && this.data.callbackOnClose) {
        this.matDialogRef.close(true);
      } else if (!userLocation?.country?.id && shouldReload) {
        this.matDialogRef.close();
        this.authReload();
      } else {
        this.matDialogRef.close();
      }
    });
  }
  theme2loginSetings = {
    showCardInput: false,
    showOtp: false,
  };
  mobileLengthCheck(lengthToCheck) {
    this.theme2loginSetings.showCardInput =
      this.mobNum.length == lengthToCheck ? true : false;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
