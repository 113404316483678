import { Component, Inject, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { GlobalConstants } from '../../../pages/common/global-variables';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from 'src/app/pages/common/authentication.service';
import { ApiService } from 'src/app/pages/common/api.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  providers: [GlobalConstants]
})
export class Layout1Component implements OnInit {

  urlcode = "";
  processedToken = false;
  uid: any;
  apiclient: any;
  userCards: any = [];
  cardSegment: string = "";
  logginLoader: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public GlobalConstants: GlobalConstants,
    public authService: AuthenticationService,
    public _apiService: ApiService,
    @Inject(DOCUMENT) private _document: HTMLDocument
  ) {
    this.cardSegment = this.getCardCardSegment();
    this.authService.isUserLoggedIn.subscribe((res:any) => {
      this.cardSegment = this.getCardCardSegment();
    })
    this.route.queryParams.subscribe((params) => {
      if (params.code) {
        this.urlcode = params.code;
      } else if (params.data) {
        this.urlcode = params.data;
        // this.urlcode = 'cKG3tlZT+OB3R4X60H60obFPbWCWcegdboFgUU1CNo1IgYgDq4vh3mCzBDppW/aDHynMvuIailvvQPExCS3ZGilNQxctAtxCedy2RUH+ThlCY42Jl7lWtAp/FwF9YKtaojshX2lyaQ047vxQsTHwQhZzMlg6SYVNgdajTXqLr89roh5//ChPsJ36v63v6FMuLw9+VG9RCBMizbI4Ta2JRMpeuWH/B6j2YXbDYaUNf6Kw5069IVvfoQzOWgV8QGCeyiiR7V/aDqlnSOhdGN9qD/VBa1LWey62to2aubiaN4sGJ1+lQlJ/EsMHpDlzmLSnpC2/uG9uje0VXAm/VEyqDnQtKR/OmM7ltOioTGQRszuJm30/lNbku4SUhsAeuuhxwqC5UfxOemfo1ZZ4sZ3L/g2frQGCP9dZW3KIVShW5eYJi/bek6tjxCaJa9snMF1dn9xmwReUVJFhPFi81bjdyY78vkais5lHVjomKm87+V+ulucjY0nxLSIWbVoZ9h6C1bZiJc+TFL5Ot8fMm5iw2S+nGKjoNfnlUH9zkAEhytfWvhH1vmktM3QcnGhQV6xBZvAjHv6unr57ng/Ct/nqHt3S82bWi8wRhUldS9JdNH4WcK1iO0d7Y2WzTYKuuxTuOaJ70bJJaXoir9Q9sV/YItRH6BUHEKucawtfrPqhks1r1iwL/hbFFl83Besb2qme9V1QbHqWQlLBetxDDBgP3DRSUGAJyRXJRkG5koMuuK0AK20bXa89X20JytSdDT4mDuqyRcNZkp9SoEqbhErx3GR0sWaZ1vjB2nXeqOSTzpK27OmZAha8Nn8mYiRriZlJsxtDhDVhMzXCxtDvv4RMocmJihMuHmgf0DmozvNSj/I9I59pyB7RxY2V8szGF5yWlP9LTctPAQAfzqGs4uBHN/JVT03PsTRJp1VmA4UWrB5wnrbahYpcpomNMjS/blegT+sfjZURye/LRGwjJcZxe6cG8A/q95LQ+Y4I5o4jA04Es2RppzEf/5tXmR80GFWWCZp9kbmX+aY4WVmzPNduzo6Im0YweCvGb1kPLUu+UJyywjtNVGkUvzRM4Qj4dPlgpMyjCRFwyO4Gn/F8i8lud6W1X8at5PfcMPP3/tCwpGoT9yjId3pTYB9snjsICwgb';
        // router.navigate([], { queryParams: { code: null }, queryParamsHandling: 'merge' })
        let qParams: Params = {};
        this.setQueryParams(qParams);
      } else if (params.tk) {
        this.removeLocalStorage();
        if (atob(params.tk) != "no-token") {
          this.urlcode = atob(params.tk);
          this.processedToken = true;
        }
        if (params.ru) {
          let returnUrl = JSON.stringify(atob(params.ru).trim());
          localStorage.setItem("returnUrl", returnUrl);
        }
        if (params.cf) {
          let channel_ref = params.cf.toLowerCase();
          localStorage.setItem("channel_ref", channel_ref);
          if (channel_ref == "mb") {
            localStorage.setItem("appUser", "true");
          }
        }
        if (params.ct) {
          let customer_type = atob(params.ct).toLowerCase();
          localStorage.setItem("customer_type", customer_type.toLowerCase());
          let qParams: Params = {};
          this.setQueryParams(qParams);
          if (customer_type && customer_type != "etb") {
            this.reloadInit();
            localStorage.setItem("urlcode", "no-token");
            return false;
          }
        }
      } else if (params.tok) {
        this.urlcode = atob(params.tok);
        this.processUserWithToken(this.urlcode);
        console.log(params.tok);
      } else if (params.token) {
        this.removeLocalStorage();
      }
    });
    if (this.urlcode) {
      if (!this.processedToken) {
        this.logginLoader = true;
        this.processUrlToken();
      } else {
        this.processEncodedUrlToken();
      }
    }
  }

  getCardCardSegment() {
      if(localStorage.getItem("userCard")) {
      let userCard = JSON.parse(localStorage.getItem("userCard"));
      if(userCard && userCard?.attributes && userCard?.attributes?.display_name) {
        if(userCard?.attributes?.display_name.toLocaleLowerCase().includes("select")) {
          return "select";
        } else if(userCard?.attributes?.display_name.toLocaleLowerCase().includes("wealth")) {
          return "wealth";
        } else if(userCard?.attributes?.display_name.toLocaleLowerCase().includes("private")) {
          return "private";
        } else if(userCard?.attributes?.display_name.toLocaleLowerCase().includes("classic")) {
          return "classic";
        } else if(userCard?.attributes?.display_name.toLocaleLowerCase().includes("family")) {
          return "first_family";
        } else if(userCard?.attributes?.display_name.toLocaleLowerCase().includes("millenial")) {
          return "millenial";
        }
      } else return "";
    } else {
      return "";
    }
  }

  processUrlToken() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("card_verified");
    this._apiService
      .createTokenUser(this.urlcode, this.uid || null, this.apiclient || null)
      .subscribe(
        (res: any) => {
          if (res.token) {
            var user = {
              token: res.token,
            };

            if (user && user.token) {
              localStorage.setItem("currentUser", JSON.stringify(user));
              localStorage.setItem("card_verified", "true");
              localStorage.setItem("urlcode", this.urlcode);
              sessionStorage.setItem("userLoggedInCard", res.card_id);
              this._apiService.getUserDetails().subscribe((resp: any) => {
                this._apiService.getCards().subscribe((response) => {
                  if (response && response.data && response.data.length > 0) {
                    let card = response.data.find(
                      (card) => card.id == res.card_id
                    );
                    var userCards = [];
                    if (this.GlobalConstants.client === "idfc-bank-gi") {
                      userCards = response.data.filter((card) => {
                        return (
                          card.attributes.card_type.toLowerCase() === "debit"
                        );
                      });
                    } else {
                      userCards = response.data;
                    }
                    localStorage.setItem("userCard", JSON.stringify(card));
                    localStorage.setItem(
                      "userCards",
                      JSON.stringify(userCards)
                    );
                    this.authReload();
                  } else {
                    window.location.href = "/";
                  }
                });
              });
            }
            this.processUserWithToken(res.token);
          } else if (res.req_data && res.req_data.customer_type) {
            localStorage.setItem("customer_type", res.req_data.customer_type);
            this.logginLoader = false;
          }
        },
        (err) => {
          this.logginLoader = false;
        }
      );
  }

  authReload() {
    if( this.urlcode ) {
      window.location.href = "/";
    } else {
      location.reload()
    }
  }

  processEncodedUrlToken() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("card_verified");
  }

  reloadInit() {
    this.authService.isUserLoggedIn.emit(true);
  }

  setQueryParams(qParams) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: qParams,
      queryParamsHandling: "",
    });
  }

  processUserWithToken(token) {
    var user = {
      token: token,
    };

    if (user && user.token) {
      this.logginLoader = true;
      localStorage.setItem("currentUser", JSON.stringify(user));
      localStorage.setItem("card_verified", "true");
      localStorage.setItem("urlcode", this.urlcode);
      this._apiService.getUserDetails().subscribe((res: any) => {
        this._apiService.getCards().subscribe((response) => {
          if (response && response.data && response.data.length > 0) {
            if (this.GlobalConstants.client === "idfc-bank-gi") {
              this.userCards = response.data.filter((card) => {
                return card.attributes.card_type.toLowerCase() === "debit";
              });
            } else {
              this.userCards = response.data;
              localStorage.setItem("userCards", JSON.stringify(this.userCards));
            }
            let card;
            if (this.userCards && this.userCards.length > 0) {
              const userLoggedInCard = localStorage.getItem("userLoggedInCard");
              if(userLoggedInCard) {
                card = this.userCards.find((card) => {
                  card.id == userLoggedInCard
                })
              } else {
                card = this.userCards[0];
              }
            }
            localStorage.setItem("userCard", JSON.stringify(card));
            this.authReload();
          } else {
            window.location.href = "/";
          }
          this.logginLoader = false;
        }, error => {
          this.logginLoader = false;
        });
      });
    }
  }

  removeLocalStorage() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("card_verified");
    localStorage.removeItem("returnUrl");
    localStorage.removeItem("customer_type");
    localStorage.removeItem("channel_ref");
    localStorage.removeItem("userCards");
    localStorage.removeItem("cardSelected");
    localStorage.removeItem("userCard");
    sessionStorage.clear();
  }

  ngOnInit() {
    if (this.GlobalConstants && this.GlobalConstants.data) {
      this._document.getElementById('appFavicon').setAttribute('href', this.GlobalConstants.data.logo.favicon);
      this._document.getElementById('appColor').setAttribute('content', this.GlobalConstants.data.colors.header_bg_color);
      this._document.getElementById('appManifest').setAttribute('href', './assets/manifest/' + this.GlobalConstants.data.assets.manifest);
    }
    this.router.events.distinctUntilChanged((previous: any, current: any) => {
        if(current instanceof NavigationEnd) {
            return previous.url === current.url;
        }
        return true;
    }).subscribe((x: any) => {
        this.changeOfRoutes();
    });
  }

  closeMerchantDropDown(event) {
    var targetElem = event.target;
    var searchElem = document.getElementById('merchant-list');
    if (targetElem && searchElem && targetElem.parentElement && targetElem.parentElement.parentElement) {

      if (targetElem.parentElement.parentElement.className != "search-wrapper") {
        searchElem.style.display = 'none';
      }
    }
  }
  changeOfRoutes(){
      if(localStorage.getItem("firstLoad")){
        localStorage.removeItem('firstLoad');
      }
      if(this.GlobalConstants.data.personalized_config.datalayer){
        setTimeout(() => {
          (window as any).dataLayer.push({
            "event": "offers_Pageview",
            "lspageURL": window.location.href,
            "lspagepath": window.location.pathname
          });
        }, 500)
      }
  }
}
