import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import "hammerjs";
import * as Sentry from "@sentry/angular-ivy";
import packageJson from "../package.json";

Sentry.init({
  dsn: environment.sentryDsn,
  release: packageJson.version,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: environment.production ? "production" : "development",
  // Performance Monitoring
  tracesSampleRate: 0.2,
});

if (environment.production) {
  enableProdMode();
  if (window) {
    // window.console.log = function(){};
  }
}

document.addEventListener("DOMContentLoaded", () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
