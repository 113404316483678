export const environment = {
  production: true,
  corePath: "https://api-customer.poshvine.com/core_service/v1",
  corePathV2: "https://api-customer.poshvine.com/cs/v1",
  offerPath: "https://api-customer.poshvine.com/offer_service/v1",
  giftPathV2: "https://offers-api.poshvine.com/offer_service/v2",
  offerPathV2: "https://api-customer.poshvine.com/ps/v1",
  apiUrlBsV1: "https://api-customer.poshvine.com/bs/v1/",
  apiUrlPaV1: "https://api-customer.poshvine.com/pa/v1/",
  apiUrlLsV1: "https://api-customer.poshvine.com/ls/v1/",
  baseUrl: "https://api-customer.poshvine.com/",
  apiUrl: "https://rewardshub-stage-api.poshvine.com/api/v1/",
  stagingUrl: "https://rewardshub-stage-api.poshvine.com/",
  perksUrl: "https://sandbox-api.poshvine.com/ps/rewardshub/",
  rewardsHubApiUrl: "https://rewards-api.poshvine.com/api/v1/",
  analytics:
    "https://assets.adobedtm.com/8e068bb67c90/07a78f6404f1/launch-5e1bff55b076.min.js",
  gmApiKey: "AIzaSyAXREnbpzSGulf8xS5J43tsYwrbBFltR7k", //'AIzaSyC2AtPyxZua0AcvWyoPZi-M_WuXoOo_gRc'
  ipstackKey: "022375a36968cebce87e10e1c6447ef6",
  campaignPath: "https://api-customer.poshvine.com/cas/v1/",
  campaignEnterprisePath: "https://api-customer.poshvine.com/cas/enterprise/",
  welcomeBenefitCampaignId: "7734a634-998f-4859-9a3b-7b654bdbb0e5",
  sentryDsn:
    "https://052b3f8fcc14cc23035a4f2a217ec8cc@o515678.ingest.us.sentry.io/4506851826728960",
  SENTRY_ORG: "rzp",
  SENTRY_PROJECT: "idfc-app",
  SENTRY_AUTH_TOKEN:
    "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",
};
