import { EventEmitter, Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { PaymentService } from '../../payment/service/payment.service';
import { map, catchError, tap } from 'rxjs/operators';
import { throwError, BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class GiftcardService {
  allGiftcards = [];
  allMemberships = [];
  progress: boolean = true;
  membershipProgress: boolean = true;
  rewardPoints;
  userCardDetails;

  apiUrl = environment.apiUrlBsV1;
  offerPathV2 = environment.offerPathV2;

  burnRatioData;
  previousUrl;

  cartItems: EventEmitter<any> = new EventEmitter<[]>();
  closeModalEvent: EventEmitter<any> = new EventEmitter();

  private itemsInCart: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public itemsInCart$: Observable<any[]> = this.itemsInCart.asObservable();

  constructor(
    private http: HttpClient,
    private paymentService: PaymentService
  ) {
    // this.getAllGiftCards();
  }

  getCategories(benefit_type, discounted_project_id = false) {
    console.log(discounted_project_id)
    let httpOptions = {}
    if(discounted_project_id){
			httpOptions = {
	      headers: new HttpHeaders({
	        'discounted_project_id': 'true'
	      })
	    }
    }
    return this.http
      .get(this.offerPathV2 + `/categories?benefit_type=${benefit_type}`, httpOptions)
      .toPromise();
  }

  getMemberships(pageNo, itemsPerPage) {
    return this.http
      .get(this.offerPathV2 + '/memberships' + ('?page=' + pageNo) + ('&per_page=' + itemsPerPage))
      .toPromise();
  }

  getMembershipDetails(id) {
    return this.http
      .get(this.offerPathV2 + `/memberships/${id}`)
      .toPromise();
  }

  getAllMemberships() {
    return new Promise((resolve) => {
      this.getMemberships(1, 500).then((res: any) => {
        this.allMemberships = res.data;
        this.membershipProgress = false;
        resolve(true);
      });
    });
  }

  getGiftcards(pageNo, itemsPerPage, popular = false , discounted_project_id = false):Promise<any>{
    console.log(discounted_project_id)
    let httpOptions = {}
    if(discounted_project_id){
			httpOptions = {
	      headers: new HttpHeaders({
	        'discounted_project_id': 'true'
	      })
	    }
    }
    return this.http
      .get(this.offerPathV2 + '/gift_cards' + ('?page=' + pageNo) + ('&per_page=' + itemsPerPage) + (popular ? ('&popular=' + popular) : ''), httpOptions)
      .toPromise();
  }

  getGiftcardDetails(id, discounted_project_id = false) {
    let httpOptions = {}
    if(discounted_project_id){
			httpOptions = {
	      headers: new HttpHeaders({
	        'discounted_project_id': 'true'
	      })
	    }
    }
    return this.http
      .get(this.offerPathV2 + `/gift_cards/${id}`, httpOptions)
      .toPromise();
  }

  getAllGiftCards(discounted_project_id = false) {
    return new Promise((resolve) => {
      this.getGiftcards(1, 500, false, discounted_project_id).then((res: any) => {
        this.allGiftcards = res.data;
        this.progress = false;
        resolve(true);
      });
    });
  }
  getGiftcardsItems() {
    let ids = ['1a51953e-6439-4806-98c6-0aebeb0a617b', '19b069c0-cdd6-4979-9a4c-d9c1602ffe5b']
		return this.http.get<any>(this.offerPathV2 + '/gift_cards' + ('?page=1') + ('&per_page=' + 500), {})
			.pipe(
				map(res => res || res),
				catchError(error => throwError(error))
			);
	}
  deleteFromCart(deleteData, bookingId, discounted_project_id = false) {
    console.log(discounted_project_id)
    let httpOptions = {}
    if(discounted_project_id){
			httpOptions = {
	      headers: new HttpHeaders({
	        'discounted_project_id': 'true'
	      })
	    }
    }
    return this.http
      .post(this.apiUrl + `carts/remove_item`, deleteData, httpOptions).toPromise();
  }

  clearCartItems(discounted_project_id = false) {
    console.log(discounted_project_id)
    let httpOptions = {}
    if(discounted_project_id){
			httpOptions = {
	      headers: new HttpHeaders({
	        'discounted_project_id': 'true'
	      })
	    }
    }
    const data = {
      "category": "GiftCard"
    };
    return this.http.post(this.apiUrl + `carts/clear_cart`, data, httpOptions).toPromise();
  }

  pushToCart(cartParams, discounted_project_id = false) {
    console.log(discounted_project_id)
    let httpOptions = {}
    if(discounted_project_id){
			httpOptions = {
	      headers: new HttpHeaders({
	        'discounted_project_id': 'true'
	      })
	    }
    }
    return this.http
      .post(
        this.apiUrl + `carts/add_items`,
        cartParams,
        httpOptions
      );
  }

  getGiftcardsInCart(discounted_project_id=false) {
    console.log(discounted_project_id)
    let httpOptions = {}
    if(discounted_project_id){
			httpOptions = {
	      headers: new HttpHeaders({
	        'discounted_project_id': 'true'
	      })
	    }
    }
    return this.http
      .get(
        this.apiUrl + `carts/list_items?category=GiftCard`, httpOptions
      ).pipe(tap((res: any) => {
        console.log('cartitems', res);
        // this.itemsInCart.next(res.data);
        this.updateCartDetails(res.data)
      })).toPromise();
  }

  checkout(discounted_project_id=false) {
    console.log(discounted_project_id)
    let httpOptions = {}
    if(discounted_project_id){
			httpOptions = {
	      headers: new HttpHeaders({
	        'discounted_project_id': 'true'
	      })
	    }
    }
    const data = {
      "category": "GiftCard"
    }
    return this.http
      .post(
        this.apiUrl + `carts/checkout`, data, httpOptions
      );
  }

  createMembershipBooking(data) {
    return this.http.post(
      this.apiUrl + 'memberships/create_booking', data
    )
  }

  updateMembershipBooking(data) {
    return this.http.post(
      this.apiUrl + 'memberships/update_booking', data
    ).toPromise();
  }

  addUserData(data) {
    return this.http
      .post(
        this.apiUrl + `gift_cards/add_user_data`, data
      ).toPromise();
  }

  getBookingById(id) {
    return this.http
      .get(this.apiUrl + `bookings/${id}`)
      .toPromise();
  }

  searchGiftcards(searchquery) {
    return this.http
      .get(this.offerPathV2 + '/gift_cards' + ('?search=' + searchquery));
  }

  getBurnRatio() {
    return new Promise((resolve, reject) => {
      if (this.burnRatioData) { resolve(this.burnRatioData); }
      else {
        this.fetchBurnRatio().then((res) => {
          this.burnRatioData = res;
          resolve(this.burnRatioData);
        })
      }
    })
  }

  fetchBurnRatio() {
    return this.paymentService.getBurnRatio();
  }

  updateCartDetails(data) {
    this.itemsInCart.next(data);
  }

}
